/* @font-face {
  font-family: 'Fixedsys';
  src: url("../src/fonts/FSEX302.ttf")
} */

img{
  position:absolute;
  left:0;
  right:0;
  top:5%;
  margin-left:auto;
  margin-right:auto;
  width:50%;
}
canvas{
    font-smooth: none;
    -webkit-font-smoothing : none;
  }
  video{
    display: block;
    position: absolute;
    right: 99.999%;
  }
  html{
    /* overscroll-behavior: none; */
    /* overflow:scroll; */
    overflow:hidden;
    touch-action: pan-x pan-y;
  }
  .App {
    background-color:white;
    text-align: center;
    overflow-y:scroll;
    /* position:fixed; */
    scroll-behavior: smooth;

    width: 100%;
    height: 102%;
  }
  
  .App-header {
    display: flex;
    overflow:hidden;
  }