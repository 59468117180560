/* @font-face {
  font-family: 'Fixedsys';
  src: url("../src/fonts/FSEX302.ttf")
} */

canvas{
  font-smooth: none;
  -webkit-font-smoothing : none;
}
video{
  display: block;
  position: absolute;
  right: 99.999%;
}
html{
  overscroll-behavior: none;
  overflow:hidden;
  touch-action: pan-x pan-y;
}
body{
  overscroll-behavior: none;
}
.App {
  background-color:white;
  text-align: center;
  position:fixed;
  width: 100%;
  height: 102%;
}

.App-header {
  display: flex;
}

img,
video,
button {
  display: block;
}